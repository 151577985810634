import React from "react"
import AlbumText from "../../components/AlbumText"
import Layout from "../../components/Layout"
import People from "../../components/People"
import Tracks from "../../components/Tracks"
import colors from "../../colors"
import { getAlbum } from "../../data/albums"
import AlbumCover from "../../components/AlbumCover"
import Title from "../../components/Title"
import PressAlbum from "../../components/PressAlbum"

const album = getAlbum("we-have-a-problem")

export default () => (
  <Layout backgroundColor={colors.white} textColor={colors.problem.darkPurple}>
    <AlbumCover filename="problem.png" borderColor={colors.black} />
    <Title color={colors.problem.purple}>The Wig Out: We Have A Problem</Title>
    <p>
      Release Date: <strong>July 7th, 2020</strong>
    </p>
    <AlbumText>
      <p>
        <strong>We Have A Problem</strong> is the third EP from The Wig Out (see
        also:{" "}
        <a href="/press/baked-dwarves">Baked Dwarves Moan At Harvestman</a>,{" "}
        <a href="/press/number-two">Number Two</a>).
      </p>
      <p>
        Adapting creative constraint practices like exquisite corpse and
        conditional drawing to our pandemic-imposed isolation, The Wig Out
        follows a unique process. Each release is recorded and mixed in a period
        of four weeks, and features a unique lineup of musicians. This album
        features Dave Foley (Hypatia Lake), Adam Pearson (Swift-Tuttle), Barton
        MacGuire and Alex Sipahiaoglu (Earth To Zena), Mark Kornblum, and Steve
        Shapero.
      </p>
    </AlbumText>
    <Title color={colors.problem.purple}>Tracks</Title>
    <Tracks tracks={album.tracks} />
    <Title color={colors.problem.purple}>People</Title>
    <AlbumText>Recorded and mixed between May and June 2020 by:</AlbumText>
    <People album={album} />
  </Layout>
)
